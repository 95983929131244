
const Tiling = () => {
  return (
    <>
     
          <p>Provádíme</p>
          <ul>
            <li>montáž keramických obkladů a dlažeb</li>
            <li>
              kompletní realizace rekonstrukcí koupelen, balkónů a teras na klíč
              včetně souvisejících činností
            </li>
          </ul>
    </>
  );
};

export default Tiling;
