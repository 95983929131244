import React from "react";
import "./Cooperation.scss";
import { Link } from "react-router-dom";
import {
  IoSettingsSharp,
  IoPhonePortraitSharp,
  IoCheckmarkCircleOutline,
} from "react-icons/io5";
import ContactLink from "../ContactLink/ContactLink";

const Cooperation = () => {
  return (
    <div className="cooperation">
      <h1>Jak probíhá spolupráce</h1>
      <div className="cooperation-part">
        <IoPhonePortraitSharp className="cooperation-icon" />
        <div className="cooperation-info">
          <h3>První kontakt</h3>
          <p>Zavolejte, nebo zanechte email</p>
        </div>
      </div>
      <div className="cooperation-part">
        <IoSettingsSharp className="cooperation-icon" />
        <div className="cooperation-info">
          <h3>Doladíme detaily</h3>
          <p>Navrhneme řešení a vypracujeme cenovou nabídku zdarma</p>
        </div>
      </div>
      <div className="cooperation-part">
        <IoCheckmarkCircleOutline className="cooperation-icon" />
        <div className="cooperation-info">
          <h3>Do pár dnů máte hotovo</h3>
          <p>Působíme hlavně v Olomouckém kraji</p>
        </div>
      </div>
      <ContactLink />
    </div>
  );
};

export default Cooperation;
