import { useState, useEffect } from "react";
import "./ScrollUpButton.scss";

export const ScrollUpButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const showButtonHandler = () => {
      setShowButton(window.scrollY >= 240);
    };

    window.addEventListener("scroll", showButtonHandler);

    return () => {
      window.removeEventListener("scroll", showButtonHandler);
    };
  }, []);

  const onclickHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <button
      className={`btn ${
        showButton ? "visibility--visible" : "visibility--hidden"
      }`}
      onClick={onclickHandler}
    ></button>
  );
};

export default ScrollUpButton;
