import { useState, useEffect, useContext } from "react";
import "./Navbar.scss";
import * as IconName from "react-icons/gr";
import { NavbarData } from "../../data/NavbarData";
import Logo from "../Logo/Logo";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import SignOut from "../auth/SignOut";
import { Context } from "../../Context/AuthContext";

const Navbar = () => {
  const [extendedNavbar, setExtendedNavbar] = useState(false);
  const [navbar, setNavbar] = useState(true);
  const location = useLocation();
  const { user } = useContext(Context);

  const changeNavbarBackground = () => {
    setNavbar(window.scrollY < 80);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      changeNavbarBackground();
      window.addEventListener("scroll", changeNavbarBackground);
      return () => window.removeEventListener("scroll", changeNavbarBackground);
    } else {
      setNavbar(false);
    }
  }, [location.pathname]);

  return (
    <header>
      <nav
        className={`navbar-container ${
          extendedNavbar ? "height--max bg-color--color" : "height--min"
        } ${navbar  ? "bg-color--transparent" : "bg-color--color"}`}
      >
        <div className="navbar-inner">
          <div className="left">
            <Link to="/">
              <Logo navbar={navbar}></Logo>
            </Link>
          </div>
          <div className="right">
            <div className="navbar-link">
              {NavbarData.map((oneLink) => {
                const { id, path, title } = oneLink;

                return (
                  <NavLink
                    className={`navlink ${
                      title === "Kontaktujte nás" ? "navlink--contact-link" : ""
                    }`}
                    key={id.toString()}
                    to={path}
                  >
                    {title}
                    {/* {title === "Služby" ? (
                      <RiArrowDropDownFill className="nav-icon" />
                    ) : null} */}
                  </NavLink>
                );
              })}
              {user ? (
                <>
                  <NavLink className="navlink" to="/nastenka">
                    Nástěnka
                  </NavLink>
                  <SignOut />
                </>
              ) : (
                <Link to="/prihlaseni" className="log-in-icon">
                  <FaUser />
                  <span className="tooltip-text">Přihlášení</span>
                </Link>
              )}
              <button onClick={() => setExtendedNavbar((curr) => !curr)}>
                <div className="navbar-icon">
                  {extendedNavbar ? <IconName.GrClose /> : <IconName.GrMenu />}
                </div>
              </button>
            </div>
          </div>
        </div>
        {extendedNavbar && (
          <div className="navbar-extended">
            {NavbarData.map((item) => {
              const {id, path,cName, title} = item;

              return (
                <NavLink
                  key={id}
                  to={path}
                  onClick={() => setExtendedNavbar((curr) => !curr)}
                  className={cName}
                >
                  {title}
                </NavLink>
              );
            })}
          </div>
        )}
      </nav>
    </header>
  );
};

export default Navbar;
