import { createContext, useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Context = createContext();

const AuthContext = ({ children }) => {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribe;
    unsubscribe = onAuthStateChanged(auth, (currentUSer) => {
      setLoading(false);
      if(currentUSer) {
        setUser(currentUSer);
      } else {
        setUser(null);
      }
    });
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [auth]);

  const values = {
    user: user,
    setUser: setUser
  };

  return <Context.Provider value={values}>
    {!loading &&
    children
    }
  </Context.Provider>;
};

export { AuthContext, Context};
