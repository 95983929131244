import Slider from "../../components/Slider/Slider";
import "./Home.scss";
import { useRef } from "react";
import HomeScrollDownButton from "../../components/HomeScrollDownButton/HomeScrollDownButton";
import background from "../../images/background.jpg";
import { Link } from "react-router-dom";
import FastDemand from "../../components/FastDemand/FastDemand";

const Home = () => {
  const scrollToRef = useRef();

  return (
    <div className="home">
      <div className="home-image">
        <img src={background} alt="homephoto" />
        <div className="home-header">
          <h1>
            PAV-FASÁDY <span>s.r.o.</span>
          </h1>
          <Link className="home-link" to="/vsechny-realizace">
            Prohlédnout realizace
          </Link>
        </div>
        <HomeScrollDownButton scrollToRef={scrollToRef} />
      </div>
      {/* <div className="slider-section">
        <Slider scrollToRef={scrollToRef} />
      </div> */}
      <h3 className="header-service">
        Kompletní stavební servis nejen pro Vaši fasádu
      </h3>
      <div className="why-us-container">
        <h3 className="why-us-header">Proč se spolehnout zrovna na nás?</h3>
        <div className="why-us-inner">
          <div className="why-us-one-item">
            <span>01.</span>
            <h4>Rychlost</h4>
            <p>
              Komunikace s námi i samotná realizace probíhá flexibilně, bez
              zbytečných prodlev. Snažíme se vždy dodržovat domluvené termíny.
            </p>
          </div>
          <div className="why-us-one-item">
            <span>02.</span>
            <h4>Preciznost</h4>
            <p>
              Snaha o co nejvíce precizní práci je u nás samozřejmost. Vždy pro
              Vás navrhneme řešení, které nejvíce odpovídá potřebám.
            </p>
          </div>
          <div className="why-us-one-item">
            <span>03.</span>
            <h4>Výsledky</h4>
            <p>
              Fasáda pomáhá spořit Vaše peníze a dále plní funkci estetickou a
              ochrannou. Jak vybrat tu správnou, to Vám poradíme my.
            </p>
          </div>
        </div>
      </div>
      <FastDemand />
    </div>
  );
};

export default Home;
