import { useState, useCallback } from "react";
import ImageGallery from "../../components/ImageGallery/ImageGallery";
import Modal from "../../components/Modal/Modal";
import useFirestore from "../../hooks/useFirestore";
import "./Realization.scss";
import Pagination from "../../components/Pagination/Pagination";

const Realization = () => {
  const { docs, error } = useFirestore("images");
  // const [selectedImg, setSelectedImg] = useState(null);
  const [currentImages, setCurrentImages] = useState(null);

  const handleCurrentImages = useCallback((newCurrentImages) => {
    setCurrentImages(newCurrentImages);
  }, []);

  return (
    <div className="realization">
      <h1>Naše realizace</h1>
      
      <ImageGallery
        error={error}
        images={currentImages}
        // setSelectedImg={setSelectedImg}
      />
      {/* {selectedImg && (
        <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )} */}
      <Pagination images={docs} onHandle={handleCurrentImages} />
    </div>
  );
};

export default Realization;
