import "./ImageGallery.scss";
// import { doc, deleteDoc, collection } from "firebase/firestore";
// import { projectFirestore } from "../../firebase/config";
import { Link } from "react-router-dom";

const ImageGallery = ({ images }) => {
  // const deleteImage = async (id) => {
  //   const collectionRef = collection(projectFirestore, "images");
  //   await deleteDoc(doc(collectionRef, id));
  // };

  return (
    <div className="all-images">
      {images &&
        images.map((image) => {
          const { id, url, name } = image;

          return (
            <div
              className="one-image"
              key={id}
              // onClick={() => setSelectedImg(url)}
            >
              <Link to={`/vsechny-realizace/jedna-realizace/${id}`}>
                <article>
                  <img src={url} alt="obrázek" />
                  <label>{name}</label>
                </article>
              </Link>
              {/* <button
                className="delete-image-btn"
                onClick={() => deleteImage(id)}
              >
                Vymazat
              </button> */}
            </div>
          );
        })}
    </div>
  );
};

export default ImageGallery;
