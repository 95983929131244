import { useState } from "react";
import "./UploadForm.scss";
import ProgressBar from "../ProgressBar/ProgressBar";

const UploadForm = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const types = ["image/jpeg", "image/png", "image/jpg"];

  const changeHandler = (e) => {
    let selected = e.target.files[0];

    if (selected && types.includes(selected.type)) {
      setFile(selected);
      setError("");
    } else {
      setFile(null);
      setError("Prosím vyber obrázek (.png nebo .jpeg)");
    }
  };

  return (
    <form className="uploadform">
      <h2>Přidat stavbu</h2>
      <label htmlFor="uploadform-name">
        Název stavby:{" "}
        <input
          name="name"
          id="uploadform-name"
          type="text"
          placeholder="Název staby"
        />
      </label>
      <label htmlFor="uploadform-place">
        Místo stavby:
        <input
          name="place"
          id="uploadform-place"
          type="text"
          placeholder="Místo stavby"
        />
      </label>
      <label>
        Rok realizace:
        <select>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
        </select>
      </label>
      <label>
        Kategorie:
        <select>
          <option value="Rodinný dům">Rodinný dům</option>
          <option value="Bytový dům">Bytový dům</option>
          <option value="Rekonstrukce">Rekonstrukce</option>
          <option value="Ostatní">Ostatní</option>
        </select>
      </label>
      <label>
        Nahrát obrázek:
        <input type="file" onChange={changeHandler} />
      </label>
      <button type="submit">Potvrdit</button>
      <div className="output">
        {error && <div className="error">{error}</div>}
        {file && <div>{file.name}</div>}
        {file && <ProgressBar file={file} setFile={setFile} />}
      </div>
    </form>
  );
};

export default UploadForm;
