import { Navigate } from "react-router-dom";
import { Context } from "../Context/AuthContext";
import { useContext } from "react"; 

const ProtectedRoutes = ({children}) => {
  const {user} = useContext(Context);

  if(!user){
    return <Navigate to="prihlaseni" replace/>
  } else {
    return children;
  }
}

export default ProtectedRoutes;