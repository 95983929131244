import "./HousesReconstruction.scss";

const HousesReconstruction = () => {
  return (
    <>
          <p>Provádíme</p>
          <ul>
            <li>kompletní rekonstrukce rodinných domů</li>
            <li>nové vnitřní omítky</li>
            <li>zateplení stropů a sklepů</li>
            <li>nové podlahy</li>
            <li>výměna oken</li>
          </ul>
          <p>Rádi Vám pomůžeme s rekonstrukcí i jiných prostor.</p>
        </>
  );
};

export default HousesReconstruction;
