import { useEffect, useState } from "react";
import "./Pagination.scss";
import ReactPaginate from "react-paginate";

const Pagination = ({ images, onHandle }) => {
  const [currentImages, setCurrentImages] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [imagesOffset, setImagesOffset] = useState(0);

  useEffect(() => {
    const endOffset = imagesOffset + 12;
    setCurrentImages(images.slice(imagesOffset, endOffset));
    setPageCount(Math.ceil(images.length / 12));
  }, [images, imagesOffset]);

  useEffect(() => {
    onHandle(currentImages);
  }, [currentImages, onHandle]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 12) % images.length;
    setImagesOffset(newOffset);
  };

  return (
    <div className="pagination">
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
      />
    </div>
  );
};

export default Pagination;
