import "./OneServiceComponent.scss";
import Cooperation from "../Cooperation/Cooperation";
import BackButton from "../BackButton/BackButton";

const OneServiceComponent = ({ title, image, component }) => {

  return (
    <>
      <BackButton path="/sluzby" />
      <div className="container">
        <h1>{title}</h1>
        <div className="inner">
          <img src={image} alt="obrázek" />
          <div className="info">{component}</div>
        </div>
        <Cooperation />
      </div>
    </>
  );
};

export default OneServiceComponent;
