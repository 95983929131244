import "./Dashboard.scss";
import UploadForm from "../../components/UploadForm/UploadForm";

const Dashboard = () => {
  return (
    <>
    <h1 className="dashboard">Nástěnka</h1>
    <UploadForm />
    </>
  );
};

export default Dashboard;
