import ContactLink from "../../components/ContactLink/ContactLink";
import "./About.scss";
import logo from "../../images/logo/logo_pav_fasady-01.png";
import svg from "../../images/logo/3yyh7cjul72lsqb0eb8.svg";
import image from "../../images/Rekonstrukce_domu.jpg";

const About = ({ sectionRef }) => {
  return (
    <div className="about" ref={sectionRef}>
      <h1>O nás</h1>
      <div className="about-section">
        <div className="about-background">
          <div className="background-logo">
            <svg width="400" height="400">
              <circle
                cx="100"
                cy="100"
                r="40"
                stroke="none"
                strokeWidth="4"
                fill="rgb(93, 199, 252)"
              />
              <circle
                cx="300"
                cy="270"
                r="60"
                stroke="none"
                strokeWidth="4"
                fill="rgb(93, 199, 252,0.5)"
              />
              <circle
                cx="100"
                cy="100"
                r="40"
                stroke="none"
                strokeWidth="4"
                fill="rgb(93, 199, 252)"
              />
              <defs>
                <pattern
                  id="textPattern"
                  x="7"
                  y="7"
                  width="10"
                  height="10"
                  patternUnits="userSpaceOnUse"
                  patternTransform="rotate(45)"
                >
                  <rect x="5" y="5" width="3" height="3" fill="#333" />
                </pattern>
              </defs>
              <circle cx="235" cy="120" r="60" fill="url(#textPattern)" />
              <defs>
                <pattern
                  id="img1"
                  x="0"
                  y="0"
                  patternUnits="userSpaceOnUse"
                  width="100"
                  height="100"
                >
                  <image
                    href={image}
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMinYMin slice"
                  />
                </pattern>
              </defs>
            </svg>
            <img src={logo} alt="logo" />
          </div>
          <div className="background-content">
            <p>
              Jsme mladá dynamická firma, jejíž hlavní činností je zateplování a
              realizace fasád. Veškeré další nabízené stavební činnosti jsou
              uvedené ve službách.
            </p>
            <p>
              Naše společnost nevznikla jako úplně nová firma, ale navazuje na
              předchozí působení zakladatele a majitele firmy pana Pavla Šubrta
              v různých institucích a firmách na stavebním trhu. V prostředí
              realizace a prodeje fasád se pohybuje od roku 2003.
            </p>
          </div>
        </div>
        <div className="second-part">
          <p>
            Získané zkušenosti z předchozích let, zúročuje ve firmě PAV-FASÁDY
            s.r.o. tím, že spolupracuje při realizacích pouze s vyzkoušenými,
            prověřenými pracovníky a dodavateli, kteří zajistí konečnou
            maximální spokojenost stávajících i budoucích našich zákazníků, což
            je hlavním cílem společnosti.
          </p>
          <div className="circle-background">
            <svg width="500" height="500">
              <circle
                cx="55%"
                cy="17%"
                r="10"
                stroke="none"
                strokeWidth="4"
                fill="rgb(93, 199, 252,0.8)"
              />
              <circle
                cx="25%"
                cy="60%"
                r="10"
                stroke="none"
                strokeWidth="4"
                fill="rgb(93, 199, 252,0.8)"
              />
              <circle
                cx="45%"
                cy="65%"
                r="80"
                stroke="none"
                strokeWidth="4"
                fill="rgb(93, 199, 252,0.7)"
                fillOpacity="120%"
              />
              <text
                x="45%"
                y="65%"
                fill="black"
                stroke="black"
                strokeWidth="1"
                fontSize="40"
                fontWeight="bold"
                textAnchor="middle"
                dx="-0.1em"
                dy="-0.4em"
              >
                1
              </text>
              <text
                x="45%"
                y="70%"
                fill="black"
                stroke="black"
                strokeWidth="1"
                fontSize="20"
                textAnchor="middle"
                dx="-0.5em"
                dy="-0.7em"
              >
                tým
              </text>

              <circle
                cx="70%"
                cy="40%"
                r="90"
                stroke="none"
                strokeWidth="4"
                fill="rgb(93, 199, 252,0.7)"
                fillOpacity="100%"
              />
              <text
                x="70%"
                y="40%"
                fill="black"
                stroke="black"
                strokeWidth="1"
                fontSize="40"
                fontWeight="bold"
                textAnchor="middle"
                dx="-0.8em"
                dy="-0.8em"
              >
                80%
              </text>
              <text
                x="70%"
                y="45%"
                fill="black"
                stroke="black"
                strokeWidth="1"
                fontSize="20"
                textAnchor="middle"
                dx="-2.5em"
                dy="-0.8em"
              >
                zákazníků na
              </text>
              <text
                x="70%"
                y="50%"
                fill="black"
                stroke="black"
                strokeWidth="1"
                fontSize="20"
                textAnchor="middle"
                dx="-2.5em"
                dy="-0.8em"
              >
                doporučení
              </text>
              <defs>
                <pattern
                  id="textPattern"
                  x="7"
                  y="7"
                  width="10"
                  height="10"
                  patternUnits="userSpaceOnUse"
                  patternTransform="rotate(45)"
                >
                  <rect x="5" y="5" width="3" height="3" fill="#333" />
                </pattern>
              </defs>
              <circle
                cx="235"
                cy="240"
                fillOpacity="30%"
                r="60"
                fill="url(#textPattern)"
              />
              <circle cx="300" cy="350" r="50" fill="url(#textPattern)" />
              <defs>
                <pattern
                  id="img1"
                  x="0"
                  y="0"
                  patternUnits="userSpaceOnUse"
                  width="100"
                  height="100"
                >
                  <image
                    href={image}
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMinYMin slice"
                  />
                </pattern>
              </defs>
              <defs>
                <filter id="imageblend" primitiveUnits="objectBoundingBox">
                  <feImage
                    height="120%"
                    width="120%"
                    href={image}
                    preserveAspectRatio="none"
                  />
                  <feBlend in2="SourceGraphic" mode="multiply" />
                  <feComposite operator="in" in2="SourceGraphic" />
                </filter>
              </defs>
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <circle
                  filter="url(#imageblend)"
                  cx="30%"
                  cy="30%"
                  r="20%"
                  fillOpacity="80%"
                  fill="rgb(93, 199, 252,0.7)"
                />
                <text
                  x="30%"
                  y="30%"
                  fill="black"
                  stroke="black"
                  strokeWidth="1"
                  fontSize="40"
                  fontWeight="bold"
                  textAnchor="middle"
                  dx="-0.7em"
                  dy="-0.3em"
                >
                  50+
                </text>
                <text
                  x="30%"
                  y="35%"
                  fill="black"
                  stroke="black"
                  strokeWidth="1"
                  fontSize="30"
                  textAnchor="middle"
                  dx="-1.5em"
                  dy="-0.1em"
                >
                  realizací
                </text>
              </g>
            </svg>
            {/* <div className="blue-circle">
              <p>1</p>
              <span>tým</span>
            </div>
            <div className="blue-circle-medium">
              <p>80%</p>
              <span>zákazníků na doporučení</span>
            </div>
            <div className="circle"></div>
            <div className="blue-circle-small-first"></div>
            <div className="blue-circle-small-second"></div>
            <div className="circle-image">
              <div className="layer">
                <p>50+</p>
                <span>realizací</span>
              </div>
            </div>
            <div className="striped-circle"></div>
            <div className="striped-circle-medium"></div> */}
          </div>
        </div>
        <div className="about-paragraph">
          <h3 className="about-header">V čem porážíme konkurenci?</h3>
          <p>
            Každoročně zateplíme několik objektů v kraji, převážně rodinných
            domů. V některých obcích máme více realizací, které jsou důkazem
            dlouhodobé stability a důvěry našich zákazníků. V sekci "realizace"
            naleznete fotografie a adresy našich realizací. Doporučuji
            potencionálním zákazníkům, aby se před výběrem dodavatelské firmy
            sami přesvědčili o kvalitě naší provedené práce. 80% zákazníků k nám
            přichází na základě doporučení a dobrého jména firmy.
          </p>
        </div>
        <div className="about-paragraph">
          <h3 className="about-header">Fasády nás baví a rádi vám pomůžeme</h3>
          <p>
            Jsme zkušený tým fasádníků. Fasády zateplujeme od roku 2018 a
            rozumíme jim.
          </p>
          <p>
            Profesionální komunikace, realizace a vysoká kvalita našich služeb
            je naše poslání.
          </p>
          <ContactLink />
        </div>
      </div>
    </div>
  );
};

export default About;
