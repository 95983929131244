import { useState } from "react";
import "./ContactForm.scss";
import axios from "axios";
import { useForm } from "react-hook-form";
import ResponseModal from "../ResponseModal/ResponseModal";

const initialState = {
  name: "",
  email: "",
  tel: "",
  message: "",
};

const ContactForm = () => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: initialState,
  });
  const [showResponseModal, setShowResponseModal] = useState(false);

  const onSubmit = (data) => {
    sendEmail(data);
    setShowResponseModal(true);
  };

  const handleClose = () => {
    setShowResponseModal(false);
  };

  const sendEmail = (data) => {
    axios
      .post(
        "https://us-central1-pav-fasady-sro.cloudfunctions.net/submit",
        data
      )
      .then((res) => {
        if (res.data.status !== "success") {
          Object.assign(data, { sent: false }, { err: "fail" });
          console.log("fail", data);
          setTimeout(() => {
            reset();
          }, 6000);
        } else {
          Object.assign(data, { sent: true }, { err: "success" });
          setTimeout(() => {
            reset();
          }, 6000);
          setShowResponseModal(true);
        }
      })
      .catch((err) => {
        Object.assign(data, { err: err });
      });
  };

  return (
    <>
      {showResponseModal ? (
        <ResponseModal onClose={handleClose} />
      ) : (
        <div className="contact-container">
          <h2>Napište nám</h2>
          <form
            className="contact-form"
            onSubmit={handleSubmit(onSubmit)}
            method="POST"
          >
            <input
              type="name"
              name="name"
              placeholder="Vaše jméno"
              autoComplete="given-name"
              {...register("name", { required: "Zadejte jméno" })}
            />
            {errors.name && <p>{"*" + errors.name.message}</p>}
            <div className="contact-form-line">
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Váš email"
                  autoComplete="off"
                  {...register("email", {
                    required: "Zadejte emailovou adresu",
                    pattern: {
                      value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      message: "Zadejte platnou emailovou adresu.",
                    },
                  })}
                />
                {errors.email && <p>{"*" + errors.email.message}</p>}
              </div>
              <div>
                <input
                  type="tel"
                  name="tel"
                  placeholder="Váš telefon"
                  autoComplete="tel"
                  {...register("tel", {
                    pattern: {
                      value:
                        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                      message: "Zadejte platné telefonní číslo.",
                    },
                  })}
                />
                {errors.tel && <p>{errors.tel.message}</p>}
              </div>
            </div>
            <div>
              <textarea
                type="message"
                name="message"
                placeholder="Vaše zpráva"
                {...register("message", {
                  maxLength: {
                    value: 300,
                    message: "Zadejte pouze 300 znaků.",
                  },
                })}
              ></textarea>
              {errors.message && <p>{errors.message.message}</p>}
            </div>
            <button type="submit">Odeslat</button>
          </form>
        </div>
      )}
    </>
  );
};

export default ContactForm;
