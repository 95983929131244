import * as IconName from "react-icons/gr";
import "./ResponseModal.scss";

const ResponseModal = ({ onClose }) => {
  return (
    <div className="response-modal">
      <div className="background" onClick={onClose}></div>
      <div className="modal">
        <div onClick={onClose}>
          <IconName.GrClose />
        </div>
        <div>
          <span>Děkujeme za Vaši zprávu!!</span>
          <button>OK</button>
        </div>
      </div>
    </div>
  );
};

export default ResponseModal;
