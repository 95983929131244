import { useState, useEffect } from "react";
import useFirestore from "../../hooks/useFirestore";
// import SliderList from "../SliderList/SliderList";
import { IoChevronBackSharp, IoChevronForwardSharp } from "react-icons/io5";
import "./Slider.scss";
import { Link } from "react-router-dom";

const Slider = ({ scrollToRef }) => {
  const { docs } = useFirestore("images");
  const [index, setIndex] = useState(0);

  const sliderImages = docs.slice(0, 9);

  useEffect(() => {
    if (index < 0) {
      setIndex(sliderImages.length - 3);
    } else if (index > sliderImages.length - 3) {
      setIndex(0);
    }
  }, [index]);

  return (
    <div className="all-slide-content">
      <button onClick={() => setIndex(index - 3)}>
        <IoChevronBackSharp />
      </button>
      <div className="slide-content">
        <h3 ref={scrollToRef}>Naše realizace</h3>
        <div className="image-content">
          {sliderImages &&
            sliderImages.map((sliderImage, oneImageIndex) => {
              const { id, name, url } = sliderImage;

              // let mainClass = "next-slide";

              // if (oneImageIndex === index) {
              //   mainClass = "active-slide";
              // }

              // if (
              //   oneImageIndex === index - 1 ||
              //   (index === 0 && oneImageIndex === sliderImages.length - 1)
              // ) {
              //   mainClass = "last-slide";
              // }

              let mainClass = "third-next-slide";

              if (oneImageIndex === index) {
                mainClass = "active-slide";
              }

              if (oneImageIndex === index + 1) {
                mainClass = "next-slide";
              }

              if (oneImageIndex === index + 2) {
                mainClass = "second-next-slide";
              }

              if (
                oneImageIndex === index - 3 ||
                (index === 0 && oneImageIndex === sliderImages.length - 1)
              ) {
                mainClass = "last-slide";
              }

              return (
                <article key={id} className={mainClass}>
                  <img src={url} alt={name} />
                  <p>{name}</p>
                </article>
              );
            })}
        </div>
        <Link className="realization-link" to="/vsechny-realizace">
          Další realizace
        </Link>
      </div>
      <button onClick={() => setIndex(index + 3)}>
        <IoChevronForwardSharp />
      </button>
    </div>
  );
};

export default Slider;
