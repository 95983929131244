import ContactForm from "../../components/ContactForm/ContactForm";
import Map from "../../components/Map/Map";
import "./Contact.scss";
import {
  IoCall,
  IoHomeSharp,
  IoMailSharp,
  IoDocumentSharp,
  IoGlobeSharp,
} from "react-icons/io5";

const Contact = () => {
  return (
    <div className="contact">
      <section>
        <div>
          <ContactForm />
        </div>
        <div>
          <h2>Kontakt</h2>
          <div className="section">
            <div className="icon">
              <IoCall />
            </div>
            <p>
              Telefon: <span>+420 725 574 882</span>
            </p>
          </div>
          <div className="section">
            <div className="icon">
              <IoMailSharp />
            </div>
            <p>
              Email: <span>pavfasady@pavfasady.cz</span>
            </p>
          </div>
          <div className="section">
            <div className="icon">
              <IoHomeSharp />
            </div>
            <div>
              <p>
                Adresa: <span>PAV-FASÁDY s.r.o. </span>
              </p>
              <p>Urxova 437/4</p>
              <p>779 00 Olomouc – Lazce</p>
            </div>
          </div>
          <div className="section">
            <div className="icon">
              <IoDocumentSharp />
            </div>
            <div>
              <p>Fakturační údaje:</p>
              <p>IČ: 09373110</p>
              <p>DIČ: CZ09373110</p>
            </div>
          </div>
          <div className="section">
            <div className="icon">
              <IoGlobeSharp />
            </div>
            <div>
              <p>Web: </p>
              <p>www.pavfasady.cz</p>
            </div>
          </div>
        </div>
      </section>
      {/* <Map /> */}
    </div>
  );
};

export default Contact;
