import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import "./BackButton.scss";

const BackButton = ({path}) => {
  return (
    <div className="one-realization-link">
      <IoIosArrowBack className="icon" />
      <Link to={path}>Zpět</Link>
    </div>
  );
};

export default BackButton;
