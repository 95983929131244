import "./HomeScrollDownButton.scss";

const HomeScrollDownButton = ({ scrollToRef }) => {
  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop - 85,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className="home-scroll-down-button"
      onClick={() => handleScroll(scrollToRef.current)}
      // onClick={() => scrollToRef.current.scrollIntoView()}
    ></div>
  );
};

export default HomeScrollDownButton;
