import { orderBy, collection, query, onSnapshot } from "firebase/firestore";
import { useState, useEffect } from "react";
import { projectFirestore } from "../firebase/config";

const useFirestore = (collectionFirestore) => {
  const [docs, setDocs] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const q = query(
      collection(projectFirestore, collectionFirestore),
      orderBy("createdAt", "desc")
    );
    const unsub = onSnapshot(
      q,
      (snap) => {
        let documents = [];
        snap.forEach((doc) => {
          documents.push({ id: doc.id, ...doc.data() });
        });
        setDocs(documents);
      },
      (err) => setError(err.message)
    );

    return () => unsub();
  }, []);

  return { docs, error };
};

export default useFirestore;
