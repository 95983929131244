import { useState } from "react";
import "./SignIn.scss";
import { useNavigate } from "react-router-dom";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import { FaUser, FaLock} from "react-icons/fa";


const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const auth = getAuth();
  const navigate = useNavigate();

  const signIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password).then((user) => {
      navigate("/nastenka");
    })
    .catch((error) => setError(error.message)
    );

  };

  return (
    <div className="sign-in-container">
      <div className="sign-in-form">

      <form onSubmit={(e) => signIn(e)}>
        <h3>Přihlásit se</h3>
        <div className="sign-in-input">
          <input
            type="email"
            placeholder="Zadejte svůj email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FaUser className="icon" />
        </div>
        <div className="sign-in-input">
          <input
            type="password"
            placeholder="Zadejte svoje heslo"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FaLock className="icon" />
        </div>
        <button type="submit">Přihlásit se</button>
      </form>
      </div>
    </div>
  );
};

export default SignIn;
