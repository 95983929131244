
const FacadesImplementation = () => {
  return (
    <>
          <p>Zrealizujeme Vaši fasádu kompletně na klíč</p>
          <ul>
            <li>včetně rekonstrukce balkónů, teras</li>
            <li>nových klempířských prvků</li>
            <li>zámečnických prací</li>
          </ul>
          <p>Neváhejte se na nás obrátit, vybereme to nejlepší možné řešení.</p>
          <p> Poskytujeme kompletní odborný poradenský servis.</p>
          <p>Realizujeme i zakázky menšího rozsahu.</p>
    </>
  );
};

export default FacadesImplementation;
