import "./Error.scss";

const Error = () => {
  return (
    <div className="error-container">
      <h2>404 - File or directory not found.</h2>
    </div>
  );
};

export default Error;
