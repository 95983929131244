import "./ContactLink.scss";
import { Link } from "react-router-dom";

const ContactLink = () => {
  return (
    <div className="contact-link-container">
      <Link to="/kontakt">Kontaktujte nás</Link>
    </div>
  );
};

export default ContactLink;
