import "./Service.scss";
// import ServiceData from "../../data/ServiceData";
import { ServiceData } from "../../data/NavbarData";
import { Link } from "react-router-dom";
import { useState } from "react";

const Service = () => {
  const [showIcon, setShowIcon] = useState(null);

  return (
    <section>
      <h1>Služby</h1>
      <div className="service-tiles">
        {ServiceData.map((item) => {
          const { id, icon, title, path, image } = item;

          return (
            <Link
              to={path}
              className="one-tile"
              onMouseEnter={() => setShowIcon(id)}
              onMouseLeave={() => setShowIcon(null)}
              style={{
                backgroundImage: `linear-gradient(
                to bottom,
                rgba(5, 169, 251, 0.52) 0%,
                rgba(5, 169, 251, 0.22) 35%,
                rgba(93, 199, 252, 0.99) 100% 
              ),url("${image}")`,
              }}
              key={id}
            >
              {
                <div
                  className={` tile-icon ${
                    showIcon === id
                      ? "hideIcon-animation"
                      : "showIcon-animation"
                  }`}
                >
                  {icon}
                </div>
              }
              <div
                className={`${
                  showIcon === id
                    ? "fadeUp-animation on-blur-container"
                    : "fadeDown-animation on-blur-container"
                }`}
              >
                <h2>{title}</h2>
                {
                  <h3
                    className={`on-blur-link ${
                      showIcon === id
                        ? "visibility--visible"
                        : "visibility--hidden"
                    }`}
                  >
                    Více informací
                  </h3>
                }
              </div>
            </Link>
          );
        })}
      </div>
    </section>
  );
};

export default Service;

// className={`tile-icon ${
//   isShown ? "visibility--visible" : "visibility--hidden"
// }`}
