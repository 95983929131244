
const ApartmentsReconstruction = () => {
  return (
    <>
          <p>Provádíme</p>
          <ul>
            <li>
              kompletní rekonstrukce bytů a bytových jader na klíč včetně
              elektrikářských a instalatérských prací
            </li>
            <li>provádíme i částečné realizace dle přání a potřeb zákazníka</li>
          </ul>
          <p>Pokud chcete udělat nový hezký domov, ozvěte se nám.</p>
    </>
  );
};

export default ApartmentsReconstruction;
