import { Outlet } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ScrollToTop from "../../helpers/ScrollToTop";
import ScrollUpButton from "../../components/ScrollUpButton/ScrollUpButton";

const  SharedLayout = () => {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Outlet />
      <ScrollUpButton />
      <Footer />
    </>
  );
};

export default SharedLayout;
