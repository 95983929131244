import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Service from "./pages/Service/Service";
import Realization from "./pages/Realization/Realization";
import Reference from "./pages/Reference/Reference";
import Contact from "./pages/Contact/Contact";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import SharedLayout from "./pages/SharedLayout.js/SharedLayout";
import OneRealization from "./pages/OneRealization/OneRealization";
import Dashboard from "./pages/Dashboard/Dashboard";
import SignIn from "./components/auth/SignIn";
import ProtectedRoutes from "./helpers/ProtectedRoutes";
import { AuthContext } from "./Context/AuthContext";
import { ServiceData } from "../src/data/NavbarData";
import OneServiceComponent from "./components/OneServiceComponent/OneServiceComponent";

const App = () => {
  return (
    <AuthContext>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SharedLayout />}>
            <Route index element={<Home />} />
            <Route path="o-firme" element={<About />} />
            <Route path="sluzby">
              <Route index element={<Service />} />
              {ServiceData.map((route) => {
                const { path, id, title, image, component } = route;
                return (
                  <Route
                    key={id}
                    path={path}
                    element={
                      <OneServiceComponent title={title} image={image} component={component}/>
                    }
                  />
                );
              })}
            </Route>
            <Route path="vsechny-realizace">
              <Route index element={<Realization />} />
              <Route
                path="jedna-realizace/:realizaceId"
                element={<OneRealization />}
              />
            </Route>
            <Route path="reference" element={<Reference />} />
            <Route path="kontakt" element={<Contact />} />
            <Route path="prihlaseni" element={<SignIn />} />
            <Route
              path="nastenka"
              element={
                <ProtectedRoutes>
                  <Dashboard />
                </ProtectedRoutes>
              }
            />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthContext>
  );
};

export default App;
