import { NavbarData, ServiceData } from "../../data/NavbarData";
import "./Footer.scss";
import Logo from "../../components/Logo/Logo";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-top">
        <Link className="logo-container" to="/">
          <Logo />
          <span>PAV-FASADY s.r.o.</span>
        </Link>
        <Link className="contact-link" to="/kontakt">
          Kontaktujte nás
        </Link>
      </div>
      <hr />
      <div className="footer-middle">
        <div className="footer-middle-layout">
          <h5>Mapa webu</h5>
          {NavbarData.map((item) => (
            <Link className="link" to={item.path} key={item.id}>
              {item.title}
            </Link>
          ))}
        </div>
        <div className="footer-middle-layout">
          <h5>Služby</h5>
          {ServiceData.map((item, index) => {
            return (
              <Link className="link" to={item.path} key={index}>
                <p>{item.title}</p>
              </Link>
            );
          })}
        </div>
      </div>
      <hr />
      <div className="footer-bottom">
        <div>{`© PAV-FASÁDY s.r.o. 2018 - ${new Date().getFullYear()}`}</div>
        <div>
          Design and Code by <span>majarana</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
