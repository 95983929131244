import { useParams } from "react-router-dom";
import "./OneRealization.scss";
import useFirestore from "../../hooks/useFirestore";
import { useState, useEffect } from "react";
import { projectFirestore } from "../../firebase/config";
import { doc, onSnapshot } from "firebase/firestore";
import FastDemand from "../../components/FastDemand/FastDemand";
import BackButton from "../../components/BackButton/BackButton";

const OneRealization = () => {
  const { docs, error } = useFirestore("images");
  const [data, setData] = useState({});
  const { realizaceId } = useParams();
  const { name, url, place, date } = data;
  console.log(url);
  
  useEffect(() => {
    // projectFirestore
    //   .collection("images")
    //   .doc(realizaceId)
    //   .get()
    //   .then((document) => {
      //     console.log(document);
      //     if (document.exists) {
        //       setData(document.data());
        //     } else {
          //     }
          //   });
          const unsub = onSnapshot(
            doc(projectFirestore, "images", realizaceId),
            (doc) => {
              setData(doc.data());
              console.log(realizaceId);
              console.log(url);
      }
    );
    return () => unsub();
  }, []);

  return (
    <section className="one-realization-container">
      <BackButton path="/vsechny-realizace" />
      <div className="one-realization">
        <h2>{name}</h2>
        <div className="one-realization-content">
          <img src={url} alt="obrázek" />
          <div className="one-realization-decription">
            <article>
              <span>Místo stavby: </span>
              <p>{place}</p>
            </article>
            <article>
              <span>Rok stavby: </span>
              <p>{date}</p>
            </article>
          </div>
        </div>
      </div>
      <FastDemand />
    </section>
  );
};

export default OneRealization;
