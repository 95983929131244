import "./PlasterboardWorks.scss";

const PlasterboardWorks = () => {
  return (
    <>
      <p>Provádíme montáže</p>
          <ul>
            <li>všech druhů sádrokartonových konstrukcí</li>
            <li>sádrokrtonových příček</li>
            <li>snížení a zateplení stropů</li>
            <li>půdní vestavby</li>
            <li>rekonstrukce podkroví</li>
          </ul>
          <p>
            Neprovádíme sádrokartonové práce na žádných velkých průmyslových
            objektech.
          </p>
    </>
  );
};

export default PlasterboardWorks;
