import {
  HiOutlineWrenchScrewdriver,
  HiOutlineSquares2X2,
  HiOutlineHome,
  HiOutlineBuildingOffice2,
} from "react-icons/hi2";
import { MdOutlineBed } from "react-icons/md";
import image1 from "../images/Zatepleni_fasady.jpg";
import image2 from "../images/Rekonstrukce_domu.jpg";
import image3 from "../images/Rekonstrukce_bytu.jpg";
import image4 from "../images/Koupelny,_obklady_a_dlazby.jpg";
import image5 from "../images/Sadrokartonarske_prace.jpg";
import PlasterboardWorks from "../components/PlasterboardWorks/PlasterboardWorks";
import ApartmentsReconstruction from "../components/ApartmentsReconstruction/ApartmentsReconstruction";
import HousesReconstruction from "../components/HousesReconstruction/HousesReconstruction";
import FacadesImplementation from "../components/FacadesImplementation/FacadesImplementation";
import Tiling from "../components/Tiling/Tiling";

const NavbarData = [
  {
    id: 1,
    title: "Home",
    path: "/",
  },
  {
    id: 2,
    title: "O firmě",
    path: "/o-firme",
  },
  {
    id: 3,
    title: "Služby",
    path: "/sluzby",
  },
  {
    id: 4,
    title: "Realizace",
    path: "/vsechny-realizace",
  },
  {
    id: 5,
    title: "Reference",
    path: "/reference",
  },
  {
    id: 6,
    title: "Kontaktujte nás",
    path: "/kontakt",
  },
];

const ServiceData = [
  {
    id: 1,
    icon: <HiOutlineBuildingOffice2 />,
    title: "Zateplování fasád",
    path: "zateplovani-a-realizace-fasad",
    image: image1,
    component: <FacadesImplementation/>,
  },
  {
    id: 2,
    icon: <HiOutlineHome />,
    title: "Rekonstrukce domů",
    path: "rekonstrukce-domu-a-vnitrnich-prostor",
    image: image2,
    component: <HousesReconstruction />,
  },
  {
    id: 3,
    icon: <MdOutlineBed />,
    title: "Rekonstrukce bytů",
    path: "rekonstrukce-bytu-a-bytovych-jader",
    image: image3,
    component: <ApartmentsReconstruction />,
  },
  {
    id: 4,
    icon: <HiOutlineSquares2X2 />,
    title: "Koupelny,obklady a dlažby",
    path: "koupelny-obklady-a-dlazby",
    image: image4,
    component: <Tiling />,
  },
  {
    id: 5,
    icon: <HiOutlineWrenchScrewdriver />,
    title: "Sádrokartonářské práce",
    path: "sadrokartonarske-prace",
    image: image5,
    component: <PlasterboardWorks />,
  },
];

export { NavbarData, ServiceData };
