import "./FastDemand.scss";
import ContactLink from "../ContactLink/ContactLink";

const FastDemand = () => {
  return (
    <div className="fast-demand-container">
      <div className="fast-demand-inner">
        <span>Rychlá poptávka</span>
        <p>budeme vás kontaktovat do 24h</p> 
        <ContactLink />
      </div>
    </div>
  )
};

export default FastDemand;
