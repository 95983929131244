import "./Logo.scss";
import LogoImg from "../../images/logo/logo_pav_fasady-02.png";

const Logo = ({ navbar }) => {
  return (
    <>
      {/* nefunguje */}
      <img
        className={`logo-image ${
          navbar
            ? "border--none logo-image--normal"
            : "border--display logo-image--smaller"
        }`}
        src={LogoImg}
        alt="logo"
      />
    </>
  );
};

export default Logo;
