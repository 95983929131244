import { useState } from "react";
import StarRating from "../StarRating/StarRating";
import "./RatingModal.scss";
import * as IconName from "react-icons/gr";
import "../../components/ContactForm/ContactForm.scss";
import { projectFirestore, timestamp } from "../../firebase/config";
import { collection, addDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";

const initialState = {
  name: "",
  email: "",
  message: "",
  rating: 0,
};

const RatingModal = ({ onClose, isRated }) => {
  // const [reference, setReference] = useState({
  //   name: "",
  //   email: "",
  //   message: "",
  //   rating: 0,
  // });
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ made: "onChange", defaultValues: initialState });
  const [hover, setHover] = useState(0);

  const collectionRef = collection(projectFirestore, "referencies");

  // const handleOnchange = (e) => {
  //   const { name, value } = e.target;
  //   setReference({
  //     ...reference,
  //     [name]: value,
  //   });
  // };

  const onSubmit = async (data) => {
    const createdAt = timestamp;
    await addDoc(collectionRef, {
      ...data,
      createdAt,
    });

    setTimeout(() => {
      reset();
    }, 6000);

    // setReference({
    //   name: "",
    //   email: "",
    //   message: "",
    //   rating: 0,
    // });
    setHover(0);
    onClose();
  };

  const handleRating = (rating) => {
    // setReference({
    //   ...reference,
    //   rating: rating,
    // });
  };

  const handleOnMouseEnter = (item) => {
    setHover(item);
  };

  const handleOnMouseLeave = (leave) => {
    setHover(leave);
  };

  return (
    <div className="rating-modal-container">
      <div className="rating-background" onClick={onClose}></div>
      <div className="rating-modal">
        <div className="rating-icon" onClick={onClose}>
          <IconName.GrClose />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="rating-form-container"
        >
          <h2>Hodnocení firmy</h2>
          <input
            type="name"
            name="name"
            placeholder="Vaše jméno a příjmení"
            autoComplete="given-name"
            {...register("name", { required: "Zadejte jméno" })}
          />
          {errors.name && <p>{" * "+errors.name.message}</p>}
          <input
            type="email"
            name="email"
            placeholder="Váš email"
            autoComplete="off"
            {...register("email", {
              required: "Zadejte emailovou adresu",
              pattern: {
                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                message: "Zadejte platnou emailovou adresu.",
              },
            })}
          />
          {errors.email && <p>{" * "+errors.email.message}</p>}
          {/* {!isRated && (
            <StarRating
              isRated={isRated}
              referenceRating={reference.rating}
              hover={hover}
              onHandleRating={handleRating}
              onHandleOnMouseEnter={handleOnMouseEnter}
              onHandleOnMouseLeave={handleOnMouseLeave}
            />
          )} */}
          <textarea
            type="textarea"
            name="message"
            placeholder="Vaše zpráva"
            {...register("message", {
              maxLength: {
                value: 500,
                message: "Zadejte pouze 500 znaků.",
              },
            })}
          ></textarea>
          {errors.message && <p>{errors.message.message}</p>}
          <button>Přidat hodnocení</button>
        </form>
      </div>
    </div>
  );
};

export default RatingModal;
