import { useState } from "react";
import RatingModal from "../../components/RatingModal/RatingModal";
import "./Reference.scss";
import StarRating from "../../components/StarRating/StarRating";
import useFirestore from "../../hooks/useFirestore";
import { VscQuote } from "react-icons/vsc";

const Reference = () => {
  const { docs } = useFirestore("referencies");
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [isRated, setIsRated] = useState(true);

  const handleClick = () => {
    setShowRatingModal(true);
    setIsRated(false);
  };

  const handleClose = () => {
    setShowRatingModal(false);
    setIsRated(true);
  };

  return (
    <div className="reference-container">
      <div>
        <h1>Hodnocení firmy</h1>
      </div>
      <div>
        <a href="https://www.firmy.cz/detail/13284922-pav-fasady-s-r-o-olomouc-lazce.html">
          Hodnocení firmy našimi zákazníky
        </a>
      </div>
      <button onClick={handleClick}>Přidat hodnocení</button>
      <div>
        {showRatingModal && (
          <RatingModal isRated={isRated} onClose={handleClose} />
        )}
      </div>
      <hr />
      <div className="rating-container">
        {docs &&
          docs.map((doc) => (
            <div className="rating-show" key={doc.id}>
              <div className="message-show">        
                <p>{doc.message}</p>
              </div>
              <div className="label-show">
                <div className="rating-icon">
                  <VscQuote />
                </div>
                <div>
                  <div>{doc.name}</div>
                  {/* {isRated && (
                      <StarRating
                        isRated={isRated}
                        referenceRating={doc.rating}
                      />
                    )} */}
                  {doc.createdAt === null ? (
                    <>{"Loading"}</>
                  ) : (
                    <div>{convertTimestampToDate(doc.createdAt)}</div>
                  )}

                </div>

              </div>
              <hr />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Reference;

function convertTimestampToDate(timestamp) {
  if (timestamp) {
    const options = { month: "long", day: "numeric", year: "numeric" };

    const convertedDate = new Intl.DateTimeFormat("cs-cz", options).format(
      timestamp.toDate()
    );

    return convertedDate;
  }
}
