import { signOut, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";

const SignOut = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/prihlaseni");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="log-in-icon">
      <FaSignOutAlt onClick={() => handleSignOut()}/>
      <span className="tooltip-text">Odhlášení</span>
    </div>
  );
};

export default SignOut;
